import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <header
    style={{
      background: "#111319",
      // backgroundImage: "linear-gradient(-213deg, #5e31dc 0%, #3155dc 100%)",
      backgroundPosition: "center right, center left",
      marginBottom: `1.45rem`,
      height: "60px",
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: `0 auto`,
        maxWidth: 960,
        flex: 1,
        padding: "0 20px",
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          UFlow
        </Link>
      </h1>

      <div>
        <Link
          to="/workboard"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          工作板
        </Link>
      </div>
    </div>
  </header>
)

export default Header
