import React, { useEffect } from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { SnackbarProvider } from "notistack"

import Header from "./header"
import "./layout.css"

import SWUpdate from "./sw-update"
import { collectView } from "src/lib/av-restapi"

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  useEffect(() => {
    collectView()
  }, [])

  return (
    <>
      <SnackbarProvider maxSnack={1}>
        <Header />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <main>{children}</main>
          <footer
            css={css`
              text-align: center;
              margin: 180px 0 80px;
              color: #666;
              a {
                color: #666;
                text-decoration: none;
              }
            `}
          >
            <div
              css={css`
                margin: 30px 0;
                a {
                  margin: 20px;
                }
              `}
            >
              {/* <Link to="/about" target="_blank">
              关于我们
            </Link> */}
              <Link to="/help" target="_blank">
                帮助
              </Link>
              <Link to="/agreement" target="_blank">
                用户协议
              </Link>
              <Link to="/privacy" target="_blank">
                隐私条例
              </Link>
            </div>
            <p>
              © UFlow, {new Date().getFullYear()}
              &nbsp;·&nbsp; powered by <a href="https://ubug.io/">@ubug</a>
              &nbsp;·&nbsp;
              <a
                style={{
                  marginLeft: 10,
                }}
                href="http://www.beian.miit.gov.cn/"
                rel="noopener noreferrer"
                target="_blank"
              >
                皖ICP备17008320号-1
              </a>
            </p>
          </footer>
          <SWUpdate />
        </div>
      </SnackbarProvider>
    </>
  )
}

export default Layout
